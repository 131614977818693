import React from 'react';

import Passionate from '../../assets/home/desktop/rpi-1.png';
import Resourceful from '../../assets/home/desktop/ubuntu-core-1.png';
import Friendly from '../../assets/home/desktop/mqtt-1.png';
import TalkAbout from '../../components/TalkAbout/TalkAbout';
import BackgroundImage from '../../assets/shared/desktop/bg-pattern-leaf.svg';
import SignageImageDesktop from '../../assets/home/desktop/signage-display-perspective-4.png';
import SignageImagePhone from '../../assets/home/desktop/signage-display-perspective-4.png';
import SignageImageTablet from '../../assets/home/desktop/signage-display-perspective-4.png';


const Home = () => {
  return ( 
    <main className="background">
      <section className="hero container">
        <div className="hero-content">
          <div className="hero-content-text">
            <h1>The next generation in courtroom signage displays...Available Now!</h1>
            <p className="small-text">We are pleased to introduce an IoT solution for your courtroom signage needs. We’re always looking forward to meeting courts around the world and showing how our court signage solution can meet your courtroom display objectives.</p>
            <p className="small-text"><a href="https://youtu.be/Ek-43viecLs" target="_blank" rel="noopener noreferrer">Demo Clip</a></p>
          </div>
          <picture className="hero-content-img">
            <source srcSet={SignageImageDesktop} media="(min-width: 1024px)" />
            <source srcSet={SignageImageTablet}  media="(min-width: 768px)" />
            <img src={SignageImagePhone} alt="Cellular Frame" />
          </picture>
        </div>
      </section>
        <div className="background-image background-image-top">
          <img src={BackgroundImage} alt="Deco" />
        </div>



      {/* Work Part */}
      <section className="work background">
        <div className="work-container container">
          <div className="work-content">
          <div className="work-content-img">
            <div className="work-img-passionate">
              <img src={Passionate} alt="Raspberry Pi" />
            </div>
          </div>
          <div className="work-content-text">
            <h3>RASPBERRY PI</h3>
            <p className="text">
              Single-board, modular computer, built on the Arm architecture, and running the Linux operating system.
            </p>
          </div>
          </div>
          <div className="work-content">
          <div className="work-content-img">
            <div className="work-img-resourceful">
              <img src={Resourceful} alt="Ubuntu Core" />
            </div>
          </div>
          <div className="work-content-text">
            <h3>UBUNTU CORE</h3>
            <p className="text">
              Ubuntu Core is a minimal, secure and strictly confined operating system powering IoT and embedded devices.
            </p>
          </div>
          </div>
          <div className="work-content">
            <div className="work-content-img">
              <div className="work-img-friendly">
                <img src={Friendly} alt="AWS IoT Core MQTT Mosquitto" />
              </div>
            </div>
            <div className="work-content-text">
              <h3>MQTT</h3>
              <p className="text">
                MQTT is a lightweight, publish-subscribe, machine to machine network protocol provided by AWS or Mosquitto.
              </p>
            </div>
          </div>
        </div>
        <div className="background-image background-image-bottom">
          <img src={BackgroundImage} alt="Deco" />
        </div>
      </section>

      {/* Talk About Part */}
      <TalkAbout />
    </main>
  );
}
export default Home;
